import * as React from "react";
import ReactGA from "react-ga4";

import { useMe } from "../../../hooks/queries";

interface IUnRepliedReviewsButtonProps {
  onOpenModalUnRepliedReviews: () => void;
  innerText: string;
  gbpLink: string;
}

export const UnRepliedReviewsButton: React.FunctionComponent<
  IUnRepliedReviewsButtonProps
> = (props) => {
  const { onOpenModalUnRepliedReviews, innerText, gbpLink } = props;

  const { data: me } = useMe();

  return me?.type === "loc_admin" || me?.type === "admin" ? (
    <button className="btn btn-border" onClick={onOpenModalUnRepliedReviews}>
      {innerText}
    </button>
  ) : (
    <a
      href={gbpLink}
      className="btn btn-border"
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        ReactGA.event({
          category: "GBP Link",
          action: "Click to GBP",
          label: "reviews",
        });
      }}
    >
      {innerText}
    </a>
  );
};
