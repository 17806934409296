import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter, useHistory } from "react-router-dom";

import { ModalMessageDetails } from "../../components/ModalMessageDetails";
import { RecMessageList } from "../../components/RecMessageList";
import { useMe } from "../../hooks/queries";
import { RecMessageDataFromAPI } from "../../models/RecMessageData";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

type IRecMessagesListPageProps = RouteComponentProps<any>;

const RecMessagesListPage: React.FunctionComponent<
  IRecMessagesListPageProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`recMessageListPage.${key}`);

  const history = useHistory();
  const [organizationId, setOrganizationId] = useState<string>("");

  const [activeMessage, setActiveMessage] = useState<
    RecMessageDataFromAPI | undefined
  >(undefined);

  const { data: me } = useMe();

  const { data: recMessageData } = useQuery(
    ["recMessages", organizationId],
    () => {
      if (organizationId) {
        return DataSvc.getRecMessages(organizationId);
      }
      return [];
    }
  );

  useEffect(() => {
    setOrganizationId(props.match.params.organizationId || "");
    // eslint-disable-next-line
  }, [props.match.params.organizationId]);

  useEffect(() => {
    if (!me) {
      return;
    }

    if (
      !props.match.params.organizationId ||
      props.match.params.organizationId !== me.organization.id
    ) {
      history.push(`/recMessages/organization/${me.organization.id}`);
    } else {
      setOrganizationId(props.match.params.organizationId);
    }
    // eslint-disable-next-line
  }, [props.match.params.organizationId, me]);

  const onSelectMessage = (messageData: RecMessageDataFromAPI | undefined) => {
    setActiveMessage(messageData);
  };

  const onCloseModal = () => setActiveMessage(undefined);

  return (
    <>
      <div className="right-content rec-message-list">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("rec_messages")}</div>
          <div className="rights"></div>
        </div>

        <RecMessageList
          dataList={recMessageData || []}
          openRecMessage={onSelectMessage}
        />
      </div>
      {activeMessage && (
        <ModalMessageDetails message={activeMessage} onClose={onCloseModal} />
      )}
    </>
  );
};

export default withRouter(RecMessagesListPage);
