import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { useMe } from "../../hooks/queries";

import "./styles.scss";

type IMassPostPageProps = RouteComponentProps;

const MassPostPage: React.FunctionComponent<IMassPostPageProps> = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`massPostPage.${key}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: me } = useMe();

  return (
    <div className="right-content mass-post-page">
      <div className="top-title-bar flex-grid">
        <div className="left-title">{t("mass_post_page")}</div>
      </div>
    </div>
  );
};

export default MassPostPage;
