import dayjs from "dayjs";

import { OrgFeatures, OrgFeatureUseLimitData } from "../models/FeatureUseLimitsData";

// TODO: Generalize function for use with Org and Loc, or split into two functions
/**
 * Performs logic to determine whether a given target can use a given feature.
 * @param featureUseLimits The feature use limits data of the target
 * @param featureName The name of the feature
 * @returns True if the target has at least one more use of the given feature during the current cycle; otherwise false
 */
export const canOrgUseFeature = (
  featureUseLimits: OrgFeatureUseLimitData[],
  featureName: OrgFeatures
): boolean => {
  const useLimit: OrgFeatureUseLimitData | undefined = featureUseLimits?.find(
    (item) => item.org_feature.name === featureName
  );

  if (useLimit === undefined) {
    return false;
  }

  if (!useLimit.can_use) {
    return false;
  }

  if (useLimit.times_triggered_in_cycle >= useLimit.can_use_times) {
    return false;
  }

  const expirationDate = dayjs(useLimit.specific_rule_expire_at);
  const now = dayjs();

  if (expirationDate.isBefore(now)) {
    return false;
  }

  return true;
};
