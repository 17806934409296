/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useMutation } from "@tanstack/react-query";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter, useHistory } from "react-router-dom";

import { AccountInformation } from "../../components/AccountManagementComponents/AccountInformation";
import { MembersList } from "../../components/AccountManagementComponents/MembersList";
import { ModalEditAccountInformation } from "../../components/AccountManagementComponents/ModalEditAccountInformation";
import { ModalEditMember } from "../../components/AccountManagementComponents/ModalEditMember";
import { ModalInviteNewMember } from "../../components/AccountManagementComponents/ModalInviteNewMember";
import { useMe } from "../../hooks/queries";
import { AccountData, AccountInformationData } from "../../models/AccountData";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

type IAccountManagementPageProps = RouteComponentProps<any>;

const AccountManagementPage: React.FunctionComponent<
  IAccountManagementPageProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`accountManagementPage.${key}`);

  const history = useHistory();

  const [
    shownModalEditAccountInformation,
    setShownModalEditAccountInformation,
  ] = useState<boolean>(false); // false
  const [shownModalEditMember, setShownModalEditMember] =
    useState<boolean>(false); // false
  const [shownModalInviteNewMember, setShownModalInviteNewMember] =
    useState<boolean>(false); // false

  const [organizationId, setOrganizationId] = useState<string>("");
  const [accountId, setAccountId] = useState<string>("");

  const [accountData, setAccountData] = useState<AccountData>();

  const [editAccountData, setEditAccountData] = useState<AccountData>();

  const [accountsData, setAccountsData] = useState<AccountData[]>([]);

  const [emailToValidate, setEmailToValidate] = useState<string>();

  const [inviteExists, setInviteExists] = useState<AccountData>();

  const { data: me } = useMe();

  const { data: account } = useQuery(["account", accountId], () => {
    if (accountId !== "") {
      return DataSvc.getAccountByAccountId(accountId);
    }
    return null;
  });

  const { data: emailTaken } = useQuery(["accounts", emailToValidate], () => {
    if (emailToValidate) {
      return DataSvc.getAccounts({
        email: emailToValidate,
      }).then((accounts) => {
        if (accounts.length > 0) {
          if (accounts[0].organization_id) {
            return true;
          } else {
            setInviteExists(accounts[0]);
            return false;
          }
        } else {
          setInviteExists(undefined);
          return false;
        }
      });
    }
    return false;
  });

  const { data: accounts, refetch: refetchAccounts } = useQuery(
    ["accounts", organizationId],
    () => {
      if (!!organizationId) {
        return DataSvc.getAccounts({
          organization_id: organizationId,
          limit: 200,
        });
      }

      return [];
    }
  );

  useEffect(() => {
    if (account) {
      setAccountData(account);
    }
  }, [account]);

  useEffect(() => {
    if (accounts) {
      setAccountsData(accounts);
    }
  }, [accounts]);

  useEffect(() => {
    if (!me) {
      return;
    }

    if (!props.match.params.accountId) {
      history.push(`/account/${me.id}`);
    } else {
      setOrganizationId(me.organization?.id?.toString());

      setAccountId(props.match.params.accountId);
    }

    if (!me.organization_id) {
      // ===
      /*
      toast.error(<>{t('setup_for_this_account_not_finished')}</>);
      history.push(`/`);
      */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.accountId, me]);

  const updateAccountMutation = useMutation<
    AccountData,
    unknown,
    [string, any],
    unknown
  >(([id, data]) => {
    return DataSvc.updateAccount(id, data);
  });

  // on Save Account Information
  const onSaveAccountInformation = (formData: AccountInformationData) => {
    updateAccountMutation
      .mutateAsync([
        props.match.params.accountId,
        { first_name: formData.firstName, last_name: formData.familyName },
      ])
      .then((data) => {
        setAccountData({
          ...accountData,
          ...data,
        });
      });
  };

  const registerMutation = useMutation((data: any) => {
    return DataSvc.registerAccount(data);
  });

  // on Save Invite New Member
  const onSaveInviteNewMember = (formData: AccountInformationData) => {
    if (inviteExists) {
      if (me?.organization_id) {
        updateAccountMutation
          .mutateAsync([
            inviteExists.id.toString(),
            {
              organization_id: parseInt(me.organization.id),
              type: me?.type, // assign the same type as the operator account.
              role: formData.role,
            },
          ])
          .then(() => {
            setShownModalInviteNewMember(false);
            refetchAccounts();
          });
      }
    } else {
      registerMutation
        .mutateAsync({
          email: formData.email,
          role: formData.role,
          first_name: formData.firstName,
          last_name: formData.familyName,
          organization_id: me?.organization_id
            ? parseInt(me.organization_id)
            : null,
          type: me?.type,
        })
        .then(() => {
          setShownModalInviteNewMember(false);
          refetchAccounts();
        });
    }
  };

  // on Save Edit Member
  const onSaveEditMember = (formData: AccountInformationData) => {
    if (editAccountData) {
      updateAccountMutation
        .mutateAsync([
          editAccountData.id.toString(),
          {
            first_name: formData.firstName,
            last_name: formData.familyName,
            role: formData.role,
          },
        ])
        .then((data) => {
          const index = _.findIndex(accountsData, ["id", editAccountData.id]);
          accountsData.splice(index, 1, data);
          setAccountsData([...accountsData]);
        });
    }
  };

  // on Delete Member
  const onDeleteMember = (id: number) => {
    updateAccountMutation
      .mutateAsync([id.toString(), { organization_id: null }])
      .then(() => {
        const index = _.findIndex(accountsData, ["id", id]);
        accountsData.splice(index, 1);
        setAccountsData([...accountsData]);
      });
  };

  return (
    <>
      <div className="right-content account-management">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("account_management")}</div>
          <div className="line"></div>
        </div>

        <div className="detail-group">
          <div className="group-title">
            {t("account_information")}
            <div className="line"></div>
          </div>

          {!!accountData && (
            <AccountInformation
              userId={props.match.params.accountId}
              dataList={accountData}
              onClickEditAccountInformation={() => {
                setShownModalEditAccountInformation(true);
              }}
            />
          )}
        </div>

        {!!accountData && accountsData.length > 0 && (
          <div className="detail-group">
            <div className="group-title">
              {t("members")}
              <div className="line"></div>
            </div>

            <MembersList
              accountData={accountData}
              dataList={accountsData}
              onClickInviteMember={() => {
                setShownModalInviteNewMember(true);
                setEmailToValidate("");
                setInviteExists(undefined);
              }}
              onClickEditMember={(index: number) => {
                setEditAccountData(accountsData[index]);
                setShownModalEditMember(true);
              }}
            />
          </div>
        )}
      </div>
      {shownModalEditAccountInformation && !!accountData && (
        <ModalEditAccountInformation
          dataList={accountData}
          onClose={() => {
            setShownModalEditAccountInformation(false);
          }}
          onDelete={() => {
            setShownModalEditAccountInformation(false);
          }}
          onSave={(formData: AccountInformationData) => {
            onSaveAccountInformation(formData);
            setShownModalEditAccountInformation(false);
          }}
        />
      )}

      {shownModalInviteNewMember && !!accountData && (
        <ModalInviteNewMember
          dataList={{
            name: "",
            email: "",
          }}
          emailTaken={emailTaken || false}
          exists={!!inviteExists}
          checkEmailAvailability={setEmailToValidate}
          onClose={() => {
            setShownModalInviteNewMember(false);
          }}
          onSave={(formData: AccountInformationData) => {
            onSaveInviteNewMember(formData);
          }}
        />
      )}

      {shownModalEditMember && !!accountData && !!editAccountData && (
        <ModalEditMember
          accountData={accountData}
          dataList={editAccountData}
          onClose={() => {
            setShownModalEditMember(false);
          }}
          onDelete={(id: number) => {
            onDeleteMember(id);
            setShownModalEditMember(false);
          }}
          onSave={(formData: AccountInformationData) => {
            onSaveEditMember(formData);
            setShownModalEditMember(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(AccountManagementPage);
