import dayjs from "dayjs";

import { CreateLocalPostState } from "../../CreateLocalPostForm/utils";

export const convertLocalPostToStringSummary = (
  localPost: CreateLocalPostState
): string => {
  const details: string[] = [];

  if (localPost.event) {
    const { startDate, startTime, endDate, endTime } = localPost.event.schedule;

    let start = startDate ?? dayjs();

    start = start
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);

    if (startTime) {
      start = start
        .set("hour", startTime.get("hour"))
        .set("minute", startTime.get("minute"))
        .set("second", startTime.get("second"))
        .set("millisecond", startTime.get("millisecond"));
    }

    let end = endDate ?? dayjs();

    end = end
        .set("hour", 23)
        .set("minute", 59)
        .set("second", 0)
        .set("millisecond", 0);

    if (endTime) {
      end = end
        .set("hour", endTime.get("hour"))
        .set("minute", endTime.get("minute"))
        .set("second", endTime.get("second"))
        .set("millisecond", endTime.get("millisecond"));
    }

    const schedule = `${start.format("YYYY-MM-DD HH:mm")} ~ ${end.format(
      "YYYY-MM-DD HH:mm"
    )}`;

    details.push(`${localPost.event.title} ${schedule}`);
  }

  details.push(localPost.text);

  if (localPost.offer?.couponCode) {
    details.push(`クーポンコード: ${localPost.offer.couponCode}`);
  }

  if (localPost.offer?.redeemOnlineUrl) {
    details.push(`${localPost.offer.redeemOnlineUrl}`);
  }

  return details.join("\n\n");
};
