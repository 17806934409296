import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import { TopicTypeSelect } from "./TopicTypeSelect";
import {
  ActionType,
  CreateLocalPostState,
  EventScheduleError,
  LocalPostTopicType,
} from "./utils";
import { LocationData } from "../../../models/LocationData";
import Checkbox from "../../FormElement/Checkbox";
import { DateTimeIntervalInput } from "../../FormElement/DateTimeIntervalInput";
import { DateTimeInterval } from "../../FormElement/DateTimeIntervalInput/utils";
import DropdownSelect from "../../FormElement/DropdownSelect";
import InputBox from "../../FormElement/InputBox";
import InputTextArea from "../../FormElement/InputTextarea";
import { MediaUpload } from "../../FormElement/MediaUpload";

import "./styles.scss";

export interface ICreateLocalPostFormProps extends CreateLocalPostState {
  locationData: LocationData;
  onChangeFiles: (value: File[]) => void;
  onChangeMediaError: (error: string | undefined) => void;
  onChangeBody: (value: string) => void;
  onChangeCallToActionType: (value: ActionType) => void;
  onChangeUrl: (value: string) => void;
  onChangeTopicType: (value: LocalPostTopicType) => void;
  onChangeEventTitle: (value: string) => void;
  onChangeEventSchedule: (value: DateTimeInterval) => void;
  onChangeEventScheduleError: (value: EventScheduleError) => void;
  onChangeOfferCouponCode: (value: string) => void;
  onChangeOfferRedeemOnlineUrl: (value: string) => void;
  onChangeOfferTermsAndConditions: (value: string) => void;
  onChangePostToInstagram: (value: boolean) => void;
  onTriggerGenerateText: (
    localPostTopicType?: LocalPostTopicType,
    extraInputTitle?: string,
    startDate?: Dayjs,
    endDate?: Dayjs
  ) => void;
}

export const CreateLocalPostForm: React.FunctionComponent<
  ICreateLocalPostFormProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(
      `analysisCollectionsPage.modalCreateLocalPost.createLocalPostForm.${key}`
    );

  const {
    locationData,
    onChangeFiles,
    onChangeMediaError,
    onChangeBody,
    onChangeCallToActionType,
    onChangeUrl,
    onChangeTopicType,
    onChangeEventTitle,
    onChangeEventSchedule,
    onChangeEventScheduleError,
    onChangeOfferCouponCode,
    onChangeOfferRedeemOnlineUrl,
    onChangeOfferTermsAndConditions,
    onTriggerGenerateText,
    media,
    mediaError,
    text: messageText,
    messageTextError,
    callToAction,
    topicType,
    event,
    offer,
    postToInstagram,
    onChangePostToInstagram,
  } = props;

  const canPostInstagram =
    locationData?.locations_instagram_integrations?.length > 0;
  const hasPrimaryPhone = locationData?.pickup_fields?.has_primary_phone;

  return (
    <div className="create-local-post-form">
      <TopicTypeSelect
        changeTopicType={onChangeTopicType}
        locationData={locationData}
        topicType={topicType}
      />
      <div className="media-upload-wrapper">
        <h3>{t("add_photo")}</h3>
        <MediaUpload
          files={media}
          changeFiles={onChangeFiles}
          error={mediaError}
          changeError={onChangeMediaError}
          maxFiles={1}
          maxSizeMiB={10}
          minWidth={400}
          minHeight={300}
        />
      </div>
      {(topicType === LocalPostTopicType.EVENT ||
        topicType === LocalPostTopicType.OFFER) && (
        <div className="event">
          <h3>{t(`title.${topicType}`)}</h3>
          <InputBox
            name={"eventTitle"}
            value={event.title}
            placeholder={t(`title_placeholder.${topicType}`)}
            onChange={onChangeEventTitle}
            errorLabel={event.titleError}
          />
          <DateTimeIntervalInput
            value={event.schedule}
            onChange={onChangeEventSchedule}
            error={event.scheduleError}
            onChangeError={onChangeEventScheduleError}
            hideTimeOfDay={topicType === LocalPostTopicType.OFFER}
            minDate={MIN_DATE}
            maxDate={GET_MAX_DATE()}
          />
        </div>
      )}
      {topicType === LocalPostTopicType.STANDARD ? (
        <button
          className={`btn btn-blue ${media.length > 0 ? "" : "disabled"}`}
          onClick={() => onTriggerGenerateText()}
        >
          {t("generate_text_with_ai_from_photo")}
        </button>
      ) : (
        <button
          className={`btn btn-blue ${
            media.length > 0 &&
            event.title &&
            event.schedule.startDate &&
            event.schedule.endDate
              ? ""
              : "disabled"
          }`}
          onClick={() =>
            onTriggerGenerateText(
              topicType,
              event.title,
              event.schedule.startDate,
              event.schedule.endDate
            )
          }
        >
          {t("generate_text_with_ai_from_photo_and_details")}
        </button>
      )}
      <div className="message-body">
        <h3>{t(`message_body.${topicType}`)}</h3>
        <InputTextArea
          name="messageText"
          value={messageText}
          placeholder={t(`message_body_placeholder.${topicType}`)}
          classNameContainer={"message-body-input"}
          errorLabel={messageTextError}
          onChange={onChangeBody}
          maxChars={1500}
          showChars={true}
        />
      </div>
      {topicType === LocalPostTopicType.OFFER && (
        <div className="offer">
          <h3>{t("offer.coupon_code")}</h3>
          <InputBox
            name={"couponCode"}
            value={offer.couponCode}
            placeholder={t("offer.coupon_code_placeholder")}
            onChange={onChangeOfferCouponCode}
            errorLabel={offer.couponCodeError}
          />
          <h3>{t("offer.redeem_online_url")}</h3>
          <InputBox
            name={"redeemOnlineUrl"}
            value={offer.redeemOnlineUrl}
            placeholder={t("offer.redeem_online_url_placeholder")}
            onChange={onChangeOfferRedeemOnlineUrl}
            errorLabel={offer.redeemOnlineUrlError}
          />
          <h3>{t("offer.terms_and_conditions")}</h3>
          <div className="message-body">
            <InputTextArea
              name="termsAndConditions"
              value={offer.termsConditions}
              placeholder={t("offer.terms_and_conditions_placeholder")}
              classNameContainer={"message-body-input"}
              errorLabel={offer.termsAndConditionsError}
              onChange={onChangeOfferTermsAndConditions}
              maxChars={5000}
              showChars={true}
            />
          </div>
        </div>
      )}
      {topicType !== LocalPostTopicType.OFFER && (
        <div className="call-to-action">
          <h3>{t("add_button")}</h3>
          <DropdownSelect
            isTranslation={true}
            translationKey="analysisCollectionsPage.modalCreateLocalPost.createLocalPostForm.actionTypes"
            value={callToAction.actionType}
            items={
              hasPrimaryPhone
                ? Object.values(ActionType).map((item) => item.toString())
                : Object.values(ActionType)
                    .filter((item) => item !== ActionType.CALL)
                    .map((item) => item.toString())
            }
            onChange={onChangeCallToActionType}
          />
          {callToAction.actionType !== ActionType.ACTION_TYPE_UNSPECIFIED &&
            callToAction.actionType !== ActionType.CALL && (
              <div className="url-wrapper">
                <h3>{t("url")}</h3>
                <InputBox
                  value={callToAction.url}
                  onChange={onChangeUrl}
                  errorLabel={callToAction.urlError}
                  placeholder={t("url_placeholder")}
                />
              </div>
            )}
        </div>
      )}
      <div className="post-to-instagram-wrapper">
        {!canPostInstagram && (
          <div className="disable-overlay">
            {t("not_connected_to_instagram")}
          </div>
        )}
        <div className="post-to-instagram">
          <Checkbox
            checked={postToInstagram}
            disabled={media.length === 0 || !canPostInstagram}
            errorLabel={
              media.length === 0 && canPostInstagram
                ? t("post_to_instagram_error")
                : undefined
            }
            onChange={onChangePostToInstagram}
            label={t("post_to_instagram")}
            id={"post-to-instagram"}
          />
        </div>
      </div>
    </div>
  );
};

const MIN_DATE = dayjs("1970-01-01");
const GET_MAX_DATE = () => {
  const now = dayjs();
  return now.add(1, "year");
};
